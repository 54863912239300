let contentObj = {}

// const oriented = process.env.VUE_APP_BUILD_VERION
if (typeof window === 'object') {
  const routes = [
    {
      path: '*',
      redirect: '/'
    },
    {
      /* 报警人端 */
      path: '/',
      name: 'communication',
      component: resolve => require(['../views/communication/communication'], resolve),
      meta: {
        title: '报警通信'
      }
    }
  ]
  contentObj = {
    routes
  }
}

const baseURL = '/gcap'

module.exports = {
  ...contentObj,
  baseURL
}
